.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media screen and (max-width: 667px) {
  body {
    overflow-x: hidden !important;
  }
  .bodyNav {
    max-width: 100% !important;
    overflow-x: hidden !important;
  }
}

@import "rsuite/dist/rsuite.css";

@import "react-confirm-alert/src/react-confirm-alert.css";

.App-header {
  /* background-color: #282c34; */
  background-color: #334c64;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.myPrintDiv {
  .row {
    break-inside: avoid;
  }
}

.zoom-80 {
  zoom: 80% !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dropdown-content {
  z-index: 150 !important;
}
