@font-face {
  font-family: "Gill_Light"; /*Can be any text*/
  src: local("Gill_Light"), url("fonts/GillSans.otf") format("truetype");
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Gill_Light" !important;
  zoom: 85% !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,102,203, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
  border-color: rgb(248, 244, 246) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar-dark .navbar-toggler-icon {
  /* background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,102,203, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important; */
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
  border-color: rgb(248, 244, 246) !important;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  button {
    padding: 10px 30px;
    font-size: 20px;
    background-color: #d960ae;
    border: 0;
    cursor: pointer;
    border-radius: 4px;
    letter-spacing: 0.1em;
    color: #ffffff;
    margin-right: 20px;
    margin-bottom: 15px;
    transition: all 0.25s ease-in-out;
    &:hover {
      background-color: darken(#d960ae, 10%);
    }
    &#cancel {
      margin-right: 0;
      color: #333;
      background-color: #eddfeb;
      &:hover {
        background-color: darken(#eddfeb, 10%);
      }
    }
    &#go-back {
      display: none;
    }
    &:focus {
      border: none;
      outline: 0;
    }
  }
}

nav.navbar-full.bg-body-tertiary.fixed-top.navbar.navbar-expand-lg.navbar-light {
  background-color: #cc0000 !important;
}

button.navbar-toggler.collapsed {
  background-color: white;
}

button.navbar-toggler {
  background-color: white;
}

.navbar-light .navbar-toggler-icon {
  background-image: none;

}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30" height="30"%3e%3cpath stroke="rgba%280, 0, 0, 0.5%29" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M4 7h22M4 15h22M4 23h22"/%3e%3c/svg%3e') !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
  border-color: rgb(0, 0, 0) !important;
}
